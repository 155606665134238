import React from 'react';
import {Link } from "react-router-dom";

import footerhome from '../../Content/Img/footer-menu-home.svg';
import footermypickups from '../../Content/Img/footer-menu-my-pickups.svg';
import footerpickups from '../../Content/Img/left-menu-pickup-request.svg';
import footerhelps from '../../Content/Img/footer-menu-help.svg';
export default function Footer() {

    
        return(
<section className="botom_nav">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <ul>
                        <li className="active">
                        <Link to="/app/dashboard"  >
        
                        <img src={footerhome} alt="Home" />
                                <span>Home</span>
                        </Link>
                            
                        </li>
                        <li>
                        <Link to="/app/pickups/request"  >
        
                        <img src={footerpickups} alt="Help" />
                                <span>Book Pickup</span>
        </Link>
                           
                        </li>
                        <li>
                        <Link to="/app/pickups"  >
        
                        <img src={footermypickups} alt="My Pickups" />
                                <span>My Pickups</span>
                        </Link>
                           
                        </li>
                        
                        <li>
                        <Link to="/app/tickets/create"  >
        
                        <img src={footerhelps} alt="Help" />
                                <span>Help</span>
        </Link>
                           
                        </li>
                        
                       
                    </ul>
                </div>
            </div>
        </div>
    </section>
    )
    
}
 