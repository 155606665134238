import React ,{ useState,useEffect }from 'react';
import {Link,useHistory
} from "react-router-dom";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

const TicketsCreate = () => 
{
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState();
  const [itemscategory, setItemscategory] = React.useState([]);
  const [itemssubcategory, setItemssubcategory] = React.useState([]);
  
    // const navigate  = useNavigate();
  
    const history = useHistory();

  useEffect(() => 
  {
    // if (!isAddMode) {
        // get user and set form fields
        async function AppGetComplaintCategoryRoot() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetComplaintCategoryRoot);
            usaProfileDetails.then((responseData)=>{
                setItemscategory(responseData.DataResponse.map(({ ComplaintCategory,ComplaintCategoryID }) => ({ label: ComplaintCategory, value: ComplaintCategoryID })));
            });
          }
          async function AppGetComplaintCategoryChild() {
            const requestData={complaintcategoryid:1}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetComplaintCategoryChild);
            usaProfileDetails.then((responseData)=>{
                setItemssubcategory(responseData.DataResponse.map(({ ComplaintCategory,ComplaintCategoryID }) => ({ label: ComplaintCategory, value: ComplaintCategoryID })));
            });
          }
         
        AppGetComplaintCategoryRoot();
        AppGetComplaintCategoryChild();
        
}, []);

  const onSubmit = (data, e) => {
    setMessage({data: "Your Ticket submission is in progress...",type: "alert alert-warning",});
    var CustomerID=APIcustomerID();
        const requestData=
        {ComplaintID:0,
            ComplaintCategoryRootID:data.ComplaintCategoryRootID,
            ComplaintCategoryID:data.ComplaintCategoryID,
            CustomerLoginID:CustomerID,
            ComplaintNo:0,
            GRrNo:data.GRrNo,
            PickupNo:data.PickupNo,
            EmailID:'',
            MobileNo:data.MobileNo,
            
           
            Remarks:data.Remarks,
           // SysDate:data.SysDate,
            IsActive:'true',
            UserID:data.UserID,
            //LastUpdateDate:data.LastUpdateDate,
            LastUpdateUserID:CustomerID,
            StatusID:1,
            DMLFlag:'I'

        };

        const usaData=callApi("POST",requestData,Constant.AppCustomerComplaintIUD);
        usaData.then((responseData)=>{
          //console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Request has been successfully submitted...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
          if(responseData.DataResponse.error===null)
             {
                 setTimeout(() => {
                  history.push('/app/tickets');
                    }, 1000);
              }
    
        });
  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

        return(
            <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    Help Center

                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
       <div className="container">
           <div className="row justify-content-center mt-3">
               <div className="col-md-8">
               {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
               <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
               <div className="upload_inv att_rqt">
                   <div className="form-group">
                       <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Mobile No<sup className="text-danger">*</sup></label>
                        <input type="number" { ...register('MobileNo', { required:{value:true,message:"Please enter MobileNo"}
                            })}
                            className="form-control" placeholder="Enter MobileNo" />
                            {errors.MobileNo && (<span className=" mandatory">{errors.MobileNo.message}</span>)}
    
</div>
<p><b>Select options to Raise an Issue</b></p>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Category<sup className="text-danger">*</sup></label>
    <select className="form-control" { ...register('ComplaintCategoryRootID')}>
                               {itemscategory.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
    
</div>

<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Sub Category<sup className="text-danger">*</sup></label>
    <select className="form-control" { ...register('ComplaintCategoryID')}>
                               {itemssubcategory.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
   
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>AWB No<sup className="text-danger">*</sup></label>
    <input type="number" { ...register('GRrNo', { required:{value:true,message:"Please enter AWB No"}
                            })}
                            className="form-control" placeholder="Enter AWB No" />
                            {errors.GRrNo && (<span className=" mandatory">{errors.GRrNo.message}</span>)}
   
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Pickup No <sup className="text-danger">*</sup></label>
    <input type="number" { ...register('PickupNo', { required:{value:true,message:"Please enter PickupNo"}
                            })}
                            className="form-control" placeholder="Enter PickupNo" />
                            {errors.PickupNo && (<span className=" mandatory">{errors.PickupNo.message}</span>)}
    
</div>


<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Remarks</label>

    <textarea rows={2} { ...register('Remarks', { required:{value:true,message:"Please Pickup Instruction(Optional)"}
                           ,maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} })}
                            className="form-control" placeholder="Enter Pickup Instruction(Optional)" />
                            {errors.Remarks && (<span className=" mandatory">{errors.Remarks.message}</span>)}
</div>
<div className="form-group">
    <button type="submit" className="inv_smt">
        Submit
    </button>

</div>



</div>
               
                   </form>
                   </div>
           </div>
       </div>
    </section>
<Footer />
            </div>

            
);
};


export default TicketsCreate;