export const APiURl=
//"http://localhost:2334/";
"https://nodeapp.ngts.in/";
export const CustomerLogin=APiURl+'api/customers/CustomerLogin';
export const CustomerDetails=APiURl+'api/customers/customerdetails';
export const CustomerChangePassword=APiURl+'api/customers/ChangePassword';

export const AppGetLoadType=APiURl+'api/pickup/AppGetLoadType';
export const AppGetItemCategory=APiURl+'api/pickup/AppGetItemCategory';
export const AppGetPaymentyTerms=APiURl+'api/pickup/AppGetPaymentyTerms';
export const AppGetMode=APiURl+'api/pickup/AppGetMode';

export const PickupRequestIUD=APiURl+'api/pickup/PickupRequestIUD';
export const AppGetPickupRequest=APiURl+'api/pickup/AppGetPickupRequest';
export const AppGetPickupRequestDetails=APiURl+'api/pickup/AppGetPickupRequestDetails';

export const AppGetPickupRequestAssign=APiURl+'api/pickup/AppGetPickupRequestAssign';
export const AppGetPickupAssign=APiURl+'api/pickup/AppGetPickupAssign';
export const AppGetPickupAssignDetails=APiURl+'api/pickup/AppGetPickupAssignDetails';


export const AppGetComplaintCategoryRoot=APiURl+'api/tickets/AppGetComplaintCategoryRoot';
export const AppGetComplaintCategoryChild=APiURl+'api/tickets/AppGetComplaintCategoryChild';
export const AppCustomerComplaintIUD=APiURl+'api/tickets/AppCustomerComplaintIUD';
export const AppGetComplaints=APiURl+'api/tickets/AppGetComplaints';
export const AppGetComplaintDetails=APiURl+'api/tickets/AppGetComplaintDetails';


