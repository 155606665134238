import React,{ Component } from 'react';
import {Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Footer from '../Shared/Footer';
import report from '../../Content/Img/report.svg';
import Moment from 'moment';

export default class DriverDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {PickupList: [], id:this.props.location.state.id};
       
      }
      
      componentDidMount() {
          this.Pickuplistfunction();
          
       
        }
    
      
      
      async Pickuplistfunction()
      {
          const requestData={PickupID:this.state.id}
          const dataList=callApi("POST",requestData,Constant.AppGetPickupAssign);
          dataList.then((responseData)=>{
          this.setState({PickupList:responseData.DataResponse});
            
              });
          
           
      }
     
    render(){
        return(
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                    Pickup Driver List
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row invoice_list mt-2">
            {(this.state.PickupList.length > 0) ? this.state.PickupList.map((item, index) => {
                                        
                                       
                                          return (
                                            <div className="col-md-8 offset-md-2" key={item.PickupAssignID}>
                                            <div className="box" >
                                            {/* <Link to={'/app/pickups/details/'+{id:item.PickupID}}> */}
                                            <Link to={{pathname: '/app/pickups/driverdetail',state: {id:item.PickupAssignID}}} >
                                            
                                            
                                              
                                                    <figure>
                                                        <img src={report} alt="" />
                                                    </figure>
                                                    <div className="cont">
                                                        <p>
                                                            Pickup Request No. <strong>{item.PickupNo}</strong>
                                                        </p>
                                                        <p>
                                                        Driver Name <strong>{item.DriverName}</strong>
                                                        </p>
                                                        <p>
                                                        Driver Mobile No <strong>{item.DriverNumber}</strong>
                                                        </p>
                                                        <span>
                                                            Pickup Date <strong>{Moment(item.PickupDate).format("DD-MMM-yyyy")}</strong>
                                                        </span>
                                                        <span> <b><u>{item.StatusName}</u></b></span>
                        
                                                    </div>
                                                    </Link>
                                               
                                            </div>
                                        </div>
                                   
                        )}): <div></div>
                                    }
               
                
               
                </div>
            </div>
    </section>
    <Footer />
            </div>

            
        )
    }

}
