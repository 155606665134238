import React ,{ useState,useEffect }from 'react';
import {Link,useHistory
} from "react-router-dom";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

const PickupRequest = () => 
{
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [message, setMessage] = useState();
  const [itemsAppGetLoadType, setItemsAppGetLoadType] = React.useState([]);
  const [itemsAppGetItemCategory, setItemsAppGetItemCategory] = React.useState([]);
  const [itemsAppGetPaymentyTerms, setItemsAppGetPaymentyTerms] = React.useState([]);
  const [itemsAppGetMode, setItemsAppGetMode] = React.useState([]);
    // const navigate  = useNavigate();
   const history = useHistory();
  const password = useState({});
  password.current = watch("Newpassword", "");
  

  useEffect(() => 
  {
    // if (!isAddMode) {
        // get user and set form fields
        async function AppGetLoadType() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetLoadType);
            usaProfileDetails.then((responseData)=>{
                setItemsAppGetLoadType(responseData.DataResponse.map(({ LoadType,LoadTypeID }) => ({ label: LoadType, value: LoadTypeID })));
            });
          }
          async function AppGetItemCategory() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetItemCategory);
            usaProfileDetails.then((responseData)=>{
                setItemsAppGetItemCategory(responseData.DataResponse.map(({ ItemCategory,ItemCategoryID }) => ({ label: ItemCategory, value: ItemCategoryID })));
            });
          }
          async function AppGetPaymentyTerms() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetPaymentyTerms);
            usaProfileDetails.then((responseData)=>{
                setItemsAppGetPaymentyTerms(responseData.DataResponse.map(({ PaymentyTerms,PaymentyTermsID }) => ({ label: PaymentyTerms, value: PaymentyTermsID })));
            });
          }
          async function AppGetMode() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.AppGetMode);
            usaProfileDetails.then((responseData)=>{
                setItemsAppGetMode(responseData.DataResponse.map(({ ModeName,ModeID }) => ({ label: ModeName, value: ModeID })));
            });
          }

          AppGetLoadType();
          AppGetItemCategory();
          AppGetPaymentyTerms();
          AppGetMode();
      
}, []);

  const onSubmit = (data, e) => {
    setMessage({data: "Your Request submission is in progress...",type: "alert alert-warning",});
    var CustomerID=APIcustomerID();
        const requestData=
        {PickupID:0,
            PickupNo:0,
            PickupDate:data.PickupDate,
            CustomerLoginID:CustomerID,
            PickupName:data.PickupName,
            PickupMobileNo:data.PickupMobileNo,
            PickupHouseNo:data.PickupHouseNo,
            PickupAdd:data.PickupAdd,
            PickupPinCode:data.PickupPinCode,
            DropOffName:data.DropOffName,
            DropOffMobileNo:data.DropOffMobileNo,
            DropOffHouseNo:data.DropOffHouseNo,
            DropOffAdd:data.DropOffAdd,
            DropOffPinCode:data.DropOffPinCode,
            NoofBox:data.NoofBox,
            Weight:data.Weight,
            DimensionLBH:data.DimensionLBH,
            LoadTypeID:data.LoadTypeID,
            ModeID:data.ModeID,
            ItemCategoryID:data.ItemCategoryID,
            PaymentyTermsID:data.PaymentyTermsID,
            Remarks:data.Remarks,
           // SysDate:data.SysDate,
            PONo:data.PONo,
            IsActive:'true',
            UserID:data.UserID,
            //LastUpdateDate:data.LastUpdateDate,
            LastUpdateUserID:CustomerID,
            StatusID:1,
            ContactPersonName:data.ContactPersonName,
            ContactPhoneNo:data.ContactPhoneNo,
            DMLFlag:'I',




        };

        const usaData=callApi("POST",requestData,Constant.PickupRequestIUD);
        usaData.then((responseData)=>{
          //console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Request has been successfully submitted...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
          if(responseData.DataResponse.error===null)
             {
                 setTimeout(() => {
                    history.push('/app/dashboard');
                    }, 1000);
              }
    
        });
  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

        return(
            <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    Pickup Request
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
       <div className="container">
           <div className="row justify-content-center mt-3">
               <div className="col-md-8">
               {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
               <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                   <div className="upload_inv att_rqt">


                       <p><b>SHIPMENT DETAILS</b></p>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>PONo</label>
                           <input type="number" { ...register('PONo',
                            // { required:{value:true,message:"Please enter PONo"}
                            // }
                            )}
                            className="form-control" placeholder="Enter No of Box" />
                            {errors.PONo && (<span className=" mandatory">{errors.PONo.message}</span>)}
                          
                       </div>
                       <div className="form-group">

                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Weight</label>
                           <input type="number" { ...register('Weight', 
                        //    { required:{value:true,message:"Please enter Weight"}
                        //     }
                            )}
                            className="form-control" placeholder="Enter Weight" />
                            {errors.Weight && (<span className=" mandatory">{errors.Weight.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>No of Box</label>
                           <input type="number" { ...register('NoofBox', 
                        //    { required:{value:true,message:"Please enter No of Box"}
                        //     }
                            )}
                            className="form-control" placeholder="Enter No of Box" />
                            {errors.NoofBox && (<span className=" mandatory">{errors.NoofBox.message}</span>)}
                          
                       </div>
                      
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Dimension <sup className="text-danger">(LxBxH In Centimeters)</sup></label>
                           <input type="text" { ...register('DimensionLBH', 
                           {
                                // required:{value:true,message:"Please enter Dimension"}
                           maxLength:{value:100,message:"Please enter max 100 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} }
                           )}
                            className="form-control" placeholder="Enter Dimension" />
                            {errors.DimensionLBH && (<span className=" mandatory">{errors.DimensionLBH.message}</span>)}
                           
                       </div>
                       
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Mode of Transportation<sup className="text-danger"></sup></label>
                           <select className="form-control" { ...register('ModeID')}>
                           <option value="0">Select Mode of Transportation</option>
                               {itemsAppGetMode.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Pickup For<sup className="text-danger"></sup></label>
                           <select className="form-control" { ...register('LoadTypeID')}>
                           <option value="0">Select Pickup For</option>
                               {itemsAppGetLoadType.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Product Type<sup className="text-danger"></sup></label>
                           <select className="form-control" { ...register('ItemCategoryID')}>
                           <option value="0">Select Product Type</option>
                               {itemsAppGetItemCategory.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
                          
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Paymenty Terms<sup className="text-danger"></sup></label>
                           <select className="form-control" { ...register('PaymentyTermsID')}>
                               <option value="0">Select Paymenty Terms</option>
                               {itemsAppGetPaymentyTerms.map(({ label, value }) => (
                               <option key={value} value={value}>{label}</option>
                               ))}
                            </select>
                           
                       </div>


                       <p><b>ORIGIN</b></p>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Vendor Name<sup className="text-danger">*</sup></label>
                           <input type="text" { ...register('PickupName', { required:{value:true,message:"Please enter Vendor Name"}
                           ,maxLength:{value:100,message:"Please enter max 100 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} })}
                            className="form-control" placeholder="Enter Vendor Name" />
                            {errors.PickupName && (<span className=" mandatory">{errors.PickupName.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Contact No<sup className="text-danger">*</sup></label>
                           <input type="number" { ...register('PickupMobileNo', { required:{value:true,message:"Please enter Contact No"}
                           ,maxLength:{value:11,message:"Please enter max 11 characters"},
                           minLength:{value:10,message:"Please enter min 10 characters"} })}
                            className="form-control" placeholder="Enter Contact No" />
                            {errors.PickupMobileNo && (<span className=" mandatory">{errors.PickupMobileNo.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Address 1<sup className="text-danger">*</sup></label>
                           <input type="text" { ...register('PickupHouseNo', { required:{value:true,message:"Please enter Flat, House, Building, Apartment"}
                           ,maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:1,message:"Please enter min 1 characters"} })}
                            className="form-control" placeholder="Enter Flat, House, Building, Apartment" />
                            {errors.PickupHouseNo && (<span className=" mandatory">{errors.PickupHouseNo.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Address 2<sup className="text-danger">*</sup></label>
                           <input type="text" { ...register('PickupAdd', { required:{value:true,message:"Please enter Area, Street, Sector, City and State"}
                           ,maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} })}
                            className="form-control" placeholder="Enter Area, Street, Sector, City and State" />
                            {errors.PickupAdd && (<span className=" mandatory">{errors.PickupAdd.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Pickup PinCode<sup className="text-danger">*</sup></label>
                           <input type="number" { ...register('PickupPinCode', { required:{value:true,message:"Please Pickup PinCode"}
                           ,maxLength:{value:7,message:"Please enter max 7 characters"},
                           minLength:{value:6,message:"Please enter min 6 characters"} })}
                            className="form-control" placeholder="Enter Pincode" />
                            {errors.PickupPinCode && (<span className=" mandatory">{errors.PickupPinCode.message}</span>)}
                           
                       </div>
                       <p><b>DESTINATION</b></p>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Hotel Name<sup className="text-danger">*</sup></label>
                           <input type="text" { ...register('DropOffName', 
                           { required:{value:true,message:"Please enter Hotel Name"}
                           ,maxLength:{value:100,message:"Please enter max 100 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} }
                           )}
                            className="form-control" placeholder="Enter Hotel Name" />
                            {errors.DropOffName && (<span className=" mandatory">{errors.DropOffName.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Contact No<sup className="text-danger">*</sup></label>
                           <input type="number" { ...register('DropOffMobileNo', { required:{value:true,message:"Please enter Contact No"}
                           ,maxLength:{value:11,message:"Please enter max 11 characters"},
                           minLength:{value:10,message:"Please enter min 10 characters"} })}
                            className="form-control" placeholder="Enter Contact Person  No" />
                            {errors.DropOffMobileNo && (<span className=" mandatory">{errors.DropOffMobileNo.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Address 1<sup className="text-danger"></sup></label>
                           <input type="text" { ...register('DropOffHouseNo', 
                           { 
                            //    required:{value:true,message:"Please enter Flat, House, Building, Apartment"}
                           maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:1,message:"Please enter min 1 characters"} }
                           )}
                            className="form-control" placeholder="Enter Flat, House, Building, Apartment" />
                            {errors.DropOffHouseNo && (<span className=" mandatory">{errors.DropOffHouseNo.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Address 2<sup className="text-danger"></sup></label>
                           <input type="text" { ...register('DropOffAdd', 
                            { 
                                // required:{value:true,message:"Please enter Area, Street, Sector, City and State"}
                           maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} }
                           )}
                            className="form-control" placeholder="Enter Area, Street, Sector, City and State" />
                            {errors.DropOffAdd && (<span className=" mandatory">{errors.DropOffAdd.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>DropOff PinCode<sup className="text-danger"></sup></label>
                           <input type="number" { ...register('DropOffPinCode', 
                          { 
                            //   required:{value:true,message:"Please DropOff PinCode"}
                           maxLength:{value:7,message:"Please enter max 7 characters"},
                           minLength:{value:6,message:"Please enter min 6 characters"} }
                           )}
                            className="form-control" placeholder="Enter DropOff Pincode" />
                            {errors.DropOffPinCode && (<span className=" mandatory">{errors.DropOffPinCode.message}</span>)}
                           
                       </div>
                       <p><b>CONTACT DETAILS</b></p>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Contact Person Name<sup className="text-danger">*</sup></label>
                           <input type="text" { ...register('ContactPersonName', { required:{value:true,message:"Please enter Contact Person Name"}
                           ,maxLength:{value:100,message:"Please enter max 100 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} })}
                            className="form-control" placeholder="Enter Contact Person Name" />
                            {errors.ContactPersonName && (<span className=" mandatory">{errors.ContactPersonName.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Contact Person No<sup className="text-danger">*</sup></label>
                           <input type="number" { ...register('ContactPhoneNo', { required:{value:true,message:"Enter Contact Person  No"}
                           ,maxLength:{value:11,message:"Please enter max 11 characters"},
                           minLength:{value:10,message:"Please enter min 10 characters"} })}
                            className="form-control" placeholder="Enter Contact Person  No" />
                            {errors.ContactPhoneNo && (<span className=" mandatory">{errors.ContactPhoneNo.message}</span>)}
                           
                       </div>
                       <p><b>PICKUP DETAILS</b></p>
                       <div className="form-group date">
                           <label for="" className="d-flex align-items-center"><i className="far fa-calendar-alt"></i> Pickup Date<sup className="text-danger">*</sup></label>
                           <div className="row">
                               <div className="col-12">
                                 
                                   
                                   <input type="date" { ...register('PickupDate', { required:{value:true,message:"Please Select Date"}
                           })}
                            className="form-control" placeholder="Select Date" />
                            {errors.PickupDate && (<span className=" mandatory">{errors.PickupDate.message}</span>)}
                               </div>

                           </div>
                       </div>
                       <div className="form-group">
                           <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Pickup Instruction(Optional)</label>
                           <textarea rows={2} { ...register('Remarks', { 
                            //    required:{value:true,message:"Please Pickup Instruction(Optional)"}
                           maxLength:{value:200,message:"Please enter max 200 characters"},
                           minLength:{value:5,message:"Please enter min 5 characters"} })}
                            className="form-control" placeholder="Enter Pickup Instruction(Optional)" />
                            {errors.Remarks && (<span className=" mandatory">{errors.Remarks.message}</span>)}
                           
                       </div>
                       <div className="form-group">
                           <button type="submit" className="inv_smt">
                               Submit
                           </button>

                       </div>



                   </div>
                   </form>
                   </div>
           </div>
       </div>
    </section>
<Footer />
            </div>

            
);
};


export default PickupRequest;