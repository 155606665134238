import React,{ Component } from 'react';
import {Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

import Moment from 'moment';
export default class DriverDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {PickupList: [], id:this.props.location.state.id};
       
      }
      
      componentDidMount() {
          this.Pickuplistfunction();
          
       
        }
    
      
      
      async Pickuplistfunction()
      {
          const requestData={PickupAssignID:this.state.id}
          const dataList=callApi("POST",requestData,Constant.AppGetPickupAssignDetails);
          dataList.then((responseData)=>{
          this.setState({PickupList:responseData.DataResponse});
            
              });
          
           
      }
     
    render(){
        return(
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                    Pickup Driver Details
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row invoice_list mt-2">
            {(this.state.PickupList.length > 0) ? this.state.PickupList.map((item, index) => {
                                        
                                       
                                          return (
                                            <div className="col-md-8 offset-md-2" key={item.PickupAssignID}>
                                            <div className="upload_inv att_rqt">                      

<div className="form-group">

    <label for="" className="d-flex align-items-center">Pickup No</label>
    <p className="form-control">{item.PickupNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Estimated Pickup Date</label>
    <p className="form-control">{Moment(item.EstimatedPickupDate).format("DD-MMM-yyyy")}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Vehicle Number</label>
    <p className="form-control">{item.VehicleNumber}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Vehicle Details</label>
    <p className="form-control">{item.VehicleDetails}</p>

</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Driver Name</label>
    <p className="form-control">{item.DriverName}</p>

</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Driver Number<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DriverNumber}</p>

</div>



<div className="form-group">
    <label for="" className="d-flex align-items-center"> Pickup Instruction</label>

    <p className="form-control">{item.Remarks}</p>
</div>



</div>
                                        </div>
                                   
                        )}): <div></div>
                                    }
               
                
               
                </div>
            </div>
    </section>
    <Footer />
            </div>

            
        )
    }

}
