import React,{ Component } from 'react';
import {Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

import Moment from 'moment';
export default class TicketsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {TicketsDetails: [],id:this.props.location.state.id};
       
      }
      
      componentDidMount() {
          this.TicketsDetailsfunction();
          
       
        }
    
      
      
      async TicketsDetailsfunction()
      {
          const requestData={complaintid:this.state.id}
          const dataList=callApi("POST",requestData,Constant.AppGetComplaintDetails);
          dataList.then((responseData)=>{
          this.setState({TicketsDetails:responseData.DataResponse});
            
              });
          
           
      }
     
    render(){
        return(
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                    Ticket Details
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row invoice_list mt-2">
            {(this.state.TicketsDetails.length > 0) ? this.state.TicketsDetails.map((item, index) => {
                                        
                                       
                                          return (
                                            <div className="col-md-8 offset-md-2" key={item.ComplaintID}>
                                               <div className="upload_inv att_rqt">


<div className="form-group">

    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Complaint No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.ComplaintNo}</p>
</div>
<div className="form-group">

    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Mobile No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.MobileNo}</p>
</div>
<p><b>Select options to Raise an Issue</b></p>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Category<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.CategoryName}</p>
</div>

<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Sub Category<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.subCategoryName}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>AWB No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.GRrNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Pickup No <sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupNo}</p>
</div>


<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i> Remarks</label>

    <p className="form-control">{item.Remarks}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Date <sup className="text-danger">*</sup></label>
    <p className="form-control">{Moment(item.SysDate).format("DD-MMM-yyyy")}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Status <sup className="text-danger">*</sup></label>
    <p className="form-control">{item.StatusName}</p>
</div>



</div>
                                           
                                        </div>
                                   
                        )}): <div></div>
                                    }
               
                
               
                </div>
            </div>
    </section>
    <Footer />
            </div>

            
        )
    }

}
