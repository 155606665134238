import React,{ Component } from 'react';
import {Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';
import report from '../../Content/Img/report.svg';
import Moment from 'moment';
export default class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {TicketsList: []};
       
      }
      
      componentDidMount() {
          this.Ticketslistfunction();
          
       
        }
    
      
      
      async Ticketslistfunction()
      {
          const requestData={customerid:APIcustomerID()}
          const dataList=callApi("POST",requestData,Constant.AppGetComplaints);
          dataList.then((responseData)=>{
          this.setState({TicketsList:responseData.DataResponse});
            
              });
          
           
      }
     
    render(){
        return(
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                    My Tickets
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row invoice_list mt-2">
            {(this.state.TicketsList.length > 0) ? this.state.TicketsList.map((item, index) => {
                                        
                                       
                                          return (
                                            <div className="col-md-8 offset-md-2" key={item.ComplaintID}>
                                                <div class="box">
                                                <Link to={{pathname: '/app/tickets/details',state: {id:item.ComplaintID}}} >
                            <figure>
                            <img src={report} alt="" />
                            </figure>
                            <div class="cont">
                                <p>
                                    Complaint No. <strong>{item.ComplaintNo}</strong>
                                </p>
                                <p>
                                    Mobile No <strong>{item.MobileNo}</strong>
                                </p>
                                <p>
                                    Category <strong>{item.ComplaintCategoryRootID}</strong>
                                </p>

                                <span>
                                    Date <strong>{Moment(item.SysDate).format("DD-MMM-yyyy")}</strong>
                                </span>
                                <span> <b><u>{item.StatusName}</u></b></span>

                            </div>
                        </Link>

                    </div>
                                           
                                        </div>
                                   
                        )}): <div></div>
                                    }
               
                
               
                </div>
            </div>
    </section>
    <Footer />
            </div>

            
        )
    }

}
