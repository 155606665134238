import React,{ Component } from 'react';
import {Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

import Moment from 'moment';

export default class PickupsDetails extends Component {
    constructor(props) {

        super(props);
                this.state = {PickupDetails: [], id:this.props.location.state.id};
        
      }
      
      componentDidMount() {
       

          this.PickupDetailslistfunction();
          
       
        }
    
      
      
      async PickupDetailslistfunction()
      {
       

          const requestData={pickupid:this.state.id}
          const dataList=callApi("POST",requestData,Constant.AppGetPickupRequestDetails);
          dataList.then((responseData)=>{
          this.setState({PickupDetails:responseData.DataResponse});
            
              });
          
           
      }
     
    render(){
        return(<>
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                    Pickup Details
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row invoice_list mt-2">
            {(this.state.PickupDetails.length > 0) ? this.state.PickupDetails.map((item, index) => {
                                        
                                       
                                          return (
                                            <div className="col-md-8 offset-md-2" key={item.PickupID}>
                                             <div className="upload_inv att_rqt">


<p><b>SHIPMENT DETAILS</b></p>
<div className="form-group">

    <label for="" className="d-flex align-items-center">Pickup No</label>
    <p className="form-control">{item.PickupNo}</p>
</div>
<div className="form-group">

    <label for="" className="d-flex align-items-center">PONo</label>
    <p className="form-control">{item.PONo}</p>
</div>
<div className="form-group">

    <label for="" className="d-flex align-items-center">Weight</label>
    <p className="form-control">{item.Weight}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">No of Box</label>
    <p className="form-control">{item.NoofBox}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Dimension <sup className="text-danger">(LxBxH In Centimeters)</sup></label>
    <p className="form-control">{item.DimensionLBH}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Pickup For<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.LoadType}</p>

</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Mode of Transportation<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.modename}</p>

</div>

<div className="form-group">
    <label for="" className="d-flex align-items-center"> Product Type<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.ItemCategory}</p>

</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Paymenty Terms<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PaymentyTerms}</p>

</div>


<p><b>ORIGIN</b></p>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Vendor Name<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupName}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Contact No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupMobileNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Address 1<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupHouseNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Address 2<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupAdd}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Pickup PinCode<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.PickupPinCode}</p>
</div>
<p><b>DESTINATION</b></p>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Hotel Name<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DropOffName}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Contact No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DropOffMobileNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Address 1<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DropOffHouseNo}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Address 2<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DropOffAdd}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">DropOff PinCode<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.DropOffPinCode}</p>
</div>
<p><b>CONTACT DETAILS</b></p>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Contact Person Name<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.ContactPersonName}</p>
</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center">Contact Person No<sup className="text-danger">*</sup></label>
    <p className="form-control">{item.ContactPhoneNo}</p>
</div>
<p><b>PICKUP DETAILS</b></p>
<div className="form-group date">
    <label for="" className="d-flex align-items-center"><i className="far fa-calendar-alt"></i> Pickup Date<sup className="text-danger">*</sup></label>
    <p className="form-control">    {Moment(item.PickupDate).format("DD-MMM-yyyy")}</p>

</div>
<div className="form-group">
    <label for="" className="d-flex align-items-center"> Pickup Instruction(Optional)</label>
<p className="form-control">
    {item.Remarks}</p>
</div>



</div>
                                        </div>
                                   
                        )}): <div></div>
                                    }
               
                
               
                </div>
            </div>
    </section>
    <Footer />
            </div>
            </>
            
        )
    }

}
