import React from 'react';
import {Link} from "react-router-dom";
import user from '../../Content/Img/user.svg';
import leftpickuprequest from '../../Content/Img/left-menu-pickup-request.svg';
import leftpickupassigned from '../../Content/Img/left-menu-pickup-assigned.svg';
import leftpickups from '../../Content/Img/left-menu-my-pickups.svg';
import lefthome from '../../Content/Img/left-menu-home.svg';
import lefttickets from '../../Content/Img/left-menu-reports-tickets.svg';
import leftprofile from '../../Content/Img/left-menu-reports-profile.svg';
import leftreportpickupassigned from '../../Content/Img/left-menu-reports-pickup-assigned.svg';
import leftreportsmypickups from '../../Content/Img/left-menu-reports-my-pickups.svg';

import leftreporthelpcenter from '../../Content/Img/footer-menu-help.svg';
import leftchangepassword from '../../Content/Img/left-menu-reports-change-password.svg';

import ReactWhatsapp from 'react-whatsapp';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars,faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';
import Logout from '../Logout/Logout';

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {isMobileMenu: false};
        this.state={FirstName:'',LastName:'',MobileNumber:''}
        this.handleChangeMenu = this.handleChangeMenu.bind(this);
      }
      
      handleChangeMenu() {
        this.setState(prevState => ({
            isMobileMenu: !prevState.isMobileMenu
          }));
          console.log(this.state.isMobileMenu)
          
      }
      componentDidMount() {
        
        if(APIcustomerID()!=null)
        {
            
            const requestData={customerid:APIcustomerID()}
            const ProfileDetails=callApi("POST",requestData,Constant.CustomerDetails);
            ProfileDetails.then((responseData)=>{

           
            responseData.DataResponse.forEach(team => 
              {
                
                
                this.setState({FirstName:team.FirstName,
                 LastName:team.LastName,
                 MobileNumber:team.MobileNo
                
             });
               

                  
            });
        
                });
        }
     
      }
    render(){
        return(
            <div className="App">
            <header>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <button className="toggle_btn" onClick={this.handleChangeMenu} >
                        
                <FontAwesomeIcon icon={faBars} />

                     </button>
                  
                    <h1>
                        Dasboard
                    </h1>
                   <Logout></Logout>
                </div>
            </div>
        </div>
    </header>

    { (this.state.isMobileMenu > 0) ? 
                 (
                    <aside>
                    <div className="prof">
                        <span>NGTS</span>
                       
                    </div>
                    <ul>
            
                        <li className="active home">
                        <Link to="/app/dashboard"  >
                        <img src={lefthome} alt="mobile login" />
                                <span>Dashboard</span>
                        </Link>
                            
                        </li>
                        <li>
                        <Link to="/app/pickups/request"  >
                        <img src={leftpickuprequest} alt='pickuprequest' />
                                <span>Pickup Request</span>
                        </Link>
                           
                        </li>
                        <li>
                        <Link to="/app/pickups"  >
                        <img src={leftpickups} alt="report" />
                                <span>My Pickups</span>
                        </Link>
                          
                        </li>
                        <li>
                        <Link to="/app/pickups/assigned"  >
                        <img src={leftpickupassigned} alt="report" />
                                <span>Pickup Assigned</span>
                        </Link>
                          
                        </li>
                       
                    </ul>
                    <h4>
                        Reports
                    </h4>
                    <ul>
                        <li>
                        <Link to="/app/pickups"  >
                        <img src={leftreportsmypickups} alt="report" />
                                <span>My Pickups</span>
                        </Link>
                           
                        </li>
                        <li>
                        <Link to="/app/pickups/assigned"  >
                        <img src={leftreportpickupassigned} alt="report" />
                                <span>Pickup Assigned</span>
                        </Link>
                           
                        </li>
                     
                    </ul>
                    <h4>
                        Others
                    </h4>
                    <ul>
                        <li>
                        <Link to="/app/profile/changepassword"  >
                        <img src={leftchangepassword} alt="report" />
                                <span>Change Password</span>
                        </Link>
                            
                        </li>
                        <li>
                        <Link to="/app/profile"  >
                        <img src={leftprofile} alt="report" />
                                <span>Profile</span>
                        </Link>
                            
                        </li>
                        <li>
                        <Link to="/app/tickets/create"  >
                        <img src={leftreporthelpcenter} alt="report" />
                                <span>Help Center</span>
                        </Link>
                           
                        </li>
                        <li>
                        <Link to="/app/tickets"  >
                        <img src={lefttickets} alt="report" />
                                <span>Tickets</span>
                        </Link>
                          
                        </li>
                        <li>

                        {/* <Logout /> */}
                           
                        </li>
                    </ul>
                </aside>
                 ) : <div></div> }
   

    <section>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="profile_box">
                        <figure>
                        <img src={user} alt="mobile login" />
                        </figure>
                        <div className="det">
                            <h2>
                                {this.state.FirstName} {this.state.LastName}
                            </h2>
                            <h3>
                                Mobile No: {this.state.MobileNumber}
                            </h3>
                           
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="row service_list">
                        <div className="col-6 col-sm-4 col-lg-3">
                            <div className="box">
                            <Link to="/app/pickups/request"  >
                            <img src={leftpickuprequest} alt='pickuprequest' />
                                    <h4>
                                        Book Your Pickup
                                    </h4>
                            </Link>
                               
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3">
                            <div className="box">
                            <Link to="/app/pickups"  >
                            <img src={leftpickups} alt='task' />
                                    
                                    <h4>
                                        My Pickups
                                    </h4>
                            </Link>
                             
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3">
                            <div className="box">
                            <Link to="/app/pickups/assigned"  >
                            <img src={leftpickupassigned} alt='AttendancePunch' />
                                    
                                    <h4>
                                        Pickup Assigned
                                    </h4>
                            </Link>
                               
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="service_list">
                        <div className="box">
                        <p className="alertnote">Call us
                        <a href="tel:+919313806520" >  <FontAwesomeIcon icon={faPhoneAlt} /> +91-9313806520</a>
                        <br/>
                        <br/>
                        <ReactWhatsapp number="+91-9313806520" message="Hello !!!"  className="blue_btn" >Whatsapp</ReactWhatsapp>
                     


                           </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>

    <Footer />
      
            </div>

            
        )
    }

}
export default Home;